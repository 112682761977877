import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import AddOnRows from '../../shared/AddOnRows';
import FormCard from '../../../../components/Cards/FormCard';
import { HeadingTwo } from '../../../../components/Headings';
import { paragraph700 } from '../../../../styles/Typography';
import colors from '../../../../styles/Colors';
import DeliveryNotes from '../../shared/addOnDeliveryNotes/DeliveryNotes';
import { isRenterAddOnOnlyOrder } from '../../shared/addOnDeliveryNotes/addOnHelper';

const AddOnsBase = props => {
  const { className } = props;
  const { values, setFieldValue } = useFormikContext();
  const showDeliveryNotes = isRenterAddOnOnlyOrder(values.addOns, values.stallProductId, values.rvProductId, values.event);
  const { stalls, rv_spot, event } = values;
  const { requiredAddOns = [], venue } = event;

  const hasRequiredAddOns =
    event?.venue?.venueConfig?.requiredAddons && event?.requiredAddOns?.length > 0 && event?.addOnProducts?.filter(x => !x.disabled)?.length > 0;

  useEffect(() => {
    if (!showDeliveryNotes) {
      setFieldValue('addOnDeliveryNotes', '');
    }
  }, [showDeliveryNotes]);

  const prevStalls = useRef();
  const prevRvSpot = useRef();
  const calculatedAddOns = useRef({});

  useEffect(() => {
    const stallsChanged = checkIfProductChanged(prevStalls.current, stalls);
    const rvSpotChanged = checkIfProductChanged(prevRvSpot.current, rv_spot);
    const hasDecreased = checkIfAddOnsDecreased(calculatedAddOns.current, values.addOns);

    if (
      (stallsChanged || rvSpotChanged || hasDecreased) &&
      venue?.venueConfig?.requiredAddons &&
      requiredAddOns.length > 0 &&
      checkIfStallsOrRvsExist(stalls, rv_spot)
    ) {
      const venueAddOns = values.event?.addOnProducts?.filter(a => !a.disabled);

      if (!venueAddOns || venueAddOns.length === 0) return;

      const addonsToSum = [];

      for (let i = 0; i < requiredAddOns.length; i++) {
        const requiredAddOn = requiredAddOns[i];
        const { addOnProduct, xRefTypeId, ratio, condition } = requiredAddOn;
        const venueAddOnProduct = venueAddOns.find(x => +x.addOn?.id === +addOnProduct);

        if (!venueAddOnProduct) {
          continue; // Continue
        }

        let baseQuantity = 0;

        // Calculate base quantity based on stalls
        if (checkIfProductExist(stalls) && xRefTypeId === 1) {
          const stallsQuantity = condition === 'PRODUCT' ? +stalls.quantity : calculateNights(stalls.start, stalls.end);
          baseQuantity += stallsQuantity;
        }

        // Calculate base quantity based on rv_spot
        if (checkIfProductExist(rv_spot) && xRefTypeId === 3) {
          const rvQuantity = condition === 'PRODUCT' ? +rv_spot.quantity : calculateNights(rv_spot.start, rv_spot.end);
          baseQuantity += rvQuantity;
        }

        if (baseQuantity === 0) {
          continue;
        }

        // Calculate the total addOn quantity using the ratio
        const addOnQuantity = baseQuantity * ratio;

        addonsToSum.push({
          id: venueAddOnProduct.id,
          qty: addOnQuantity
        });
      }

      const addOnsOutputObj = addonsToSum.reduce((acc, item) => {
        if (acc[item.id]) {
          acc[item.id] += item.qty;
        } else {
          acc[item.id] = item.qty;
        }
        return acc;
      }, {});

      const mergedAddOns = { ...values.addOns, ...addOnsOutputObj };
      setFieldValue('addOns', mergedAddOns);
      calculatedAddOns.current = mergedAddOns;
    }

    prevStalls.current = stalls;
    prevRvSpot.current = rv_spot;
  }, [stalls, rv_spot, venue, requiredAddOns, setFieldValue, values.addOns]);

  const calculateNights = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24); // Convert time difference to days
  };

  const checkIfStallsOrRvsExist = (stalls, rvs) => {
    const hasStalls = !!stalls.quantity && !!stalls.start && !!stalls.end;
    const hasRvs = !!rvs.quantity && !!rvs.start && !!rvs.end;

    return hasStalls || hasRvs;
  };

  const checkIfProductExist = p => {
    return !!p.quantity && !!p.start && !!p.end;
  };

  const checkIfProductChanged = (prev, curr) => {
    if (!prev) return false;
    return prev.quantity !== curr.quantity || prev.start !== curr.start || prev.end !== curr.end;
  };

  const checkIfAddOnsDecreased = (prevAddOns, newAddOns) => {
    for (const key in prevAddOns) {
      if ((newAddOns[key] ?? false) !== false && newAddOns[key] < prevAddOns[key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <FormCard className={className} dataTestId="renter_addons">
      <div className={`${className} heading-container`}>
        <HeadingTwo label={`Add Ons`} />
        <span className={`${className} optional-text`}>{hasRequiredAddOns ? '(Required)' : '(Optional)'}</span>
      </div>
      <AddOnRows {...props} />
      {showDeliveryNotes && <DeliveryNotes />}
    </FormCard>
  );
};

const AddOns = styled(AddOnsBase)`
  & {
    .optional-text {
      color: ${colors.text.lightGray2};
      ${paragraph700}
      margin: 0 0 0 5px;
    }

    .heading-container {
      align-items: baseline;
      display: flex;
    }
  }
`;

export default AddOns;
